// Load Bootstrap JS
// just using the styles for now, not the js
//import bootstrap from 'bootstrap'

// Load Styles
import '../scss/main.scss';

// Load Fontawesome icons;
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { faAddressBook } from '@fortawesome/pro-solid-svg-icons/faAddressBook'
//import { faSunCloud } from '@fortawesome/pro-regular-svg-icons/faSunCloud'
import { faSunCloud } from '@fortawesome/pro-light-svg-icons/faSunCloud'
import { faCloudsMoon } from '@fortawesome/pro-solid-svg-icons/faCloudsMoon'
import { faCheckSquare } from '@fortawesome/pro-solid-svg-icons/faCheckSquare'
import { faTimesSquare } from '@fortawesome/pro-solid-svg-icons/faTimesSquare'
import { faSignIn } from '@fortawesome/pro-solid-svg-icons/faSignIn'
import { faPlayCircle } from '@fortawesome/pro-solid-svg-icons/faPlayCircle'
import { faCommentAltSmile } from '@fortawesome/pro-light-svg-icons/faCommentAltSmile'
import { faUserGraduate } from '@fortawesome/pro-solid-svg-icons/faUserGraduate'
import { faUserRobot } from '@fortawesome/pro-solid-svg-icons/faUserRobot'
library.add(faAddressBook, faCloudsMoon, faSunCloud, faCheckSquare, faTimesSquare, faSignIn, faCommentAltSmile, faPlayCircle, faUserGraduate)
dom.watch()